import { createContext, useState } from 'react';

const ThemeContext = createContext();

const contentData = {
    appPresent: {
      profilePicture: '/pat_fam.jpg',
      content: {
        name: 'Patrick Priestley',
        title: 'Cool Guy',
        about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id est at ligula tristique venenatis et nec velit. Duis eget rutrum mauris, a luctus turpis. Cras interdum tortor eu mi porta, blandit rhoncus erat aliquam. Sed fringilla turpis blandit turpis lobortis fermentum. Vivamus sagittis ante eget vulputate varius. Nullam sagittis vulputate commodo. Proin sed nunc ac odio aliquet efficitur. Vestibulum vestibulum tortor a pellentesque egestas. Fusce maximus lobortis enim, at vestibulum sem porta vel. Aenean maximus efficitur eros, a hendrerit lorem volutpat at. Donec maximus luctus felis eget consectetur. Aliquam sagittis est quis commodo malesuada. Vivamus eget diam quis lorem mattis blandit. Suspendisse venenatis ac ante eget interdum.',
        experience: [
          {
            company: 'Testing Company',
            position: 'Director, Cool',
            duration: 'Jan 1, 2020 - October 30, 2022',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id est at ligula tristique venenatis et nec velit. Duis eget rutrum mauris, a luctus turpis. Cras interdum tortor eu mi porta, blandit rhoncus erat aliquam. Sed fringilla turpis blandit turpis lobortis fermentum. Vivamus sagittis ante eget vulputate varius. Nullam sagittis vulputate commodo. Proin sed nunc ac odio aliquet efficitur. Vestibulum vestibulum tortor a pellentesque egestas. Fusce maximus lobortis enim, at vestibulum sem porta vel. Aenean maximus efficitur eros, a hendrerit lorem volutpat at. Donec maximus luctus felis eget consectetur. Aliquam sagittis est quis commodo malesuada. Vivamus eget diam quis lorem mattis blandit. Suspendisse venenatis ac ante eget interdum.'            ,
          },
          // ... add more experiences
        ],
        education: [
          {
            institution: 'Institution Name',
            degree: 'Degree Name',
            duration: 'Start Date - End Date',
            description: 'A brief description of your studies.',
          },
          // ... add more education entries
        ],
        // ... add more sections as needed
      },
    },
  
    app2016: {
      profilePicture: '/patrick.jpeg',
      content: {
        name: 'Your Name',
        title: 'Your Title',
        about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id est at ligula tristique venenatis et nec velit. Duis eget rutrum mauris, a luctus turpis. Cras interdum tortor eu mi porta, blandit rhoncus erat aliquam. Sed fringilla turpis blandit turpis lobortis fermentum. Vivamus sagittis ante eget vulputate varius. Nullam sagittis vulputate commodo. Proin sed nunc ac odio aliquet efficitur. Vestibulum vestibulum tortor a pellentesque egestas. Fusce maximus lobortis enim, at vestibulum sem porta vel. Aenean maximus efficitur eros, a hendrerit lorem volutpat at. Donec maximus luctus felis eget consectetur. Aliquam sagittis est quis commodo malesuada. Vivamus eget diam quis lorem mattis blandit. Suspendisse venenatis ac ante eget interdum.',
        experience: [
          {
            company: 'Testing Company',
            position: 'Director, Cool',
            duration: 'Jan 1, 2020 - October 30, 2022',
            description: 'A brief description of your role and responsibilities.',
          },
          // ... add more experiences
        ],
        education: [
          {
            institution: 'Institution Name',
            degree: 'Degree Name',
            duration: 'Start Date - End Date',
            description: 'A brief description of your studies.',
          },
          // ... add more education entries
        ],
        // ... add more sections as needed
      },
    },

    app2006: {
      profilePicture: '/pat_2006.png',
      content: {
        name: 'Your Name',
        title: 'Your Title',
        about: 'A brief description about yourself.',
        experience: [
          {
            company: 'Testing Company',
            position: 'Director, Cool',
            duration: 'Jan 1, 2020 - October 30, 2022',
            description: 'A brief description of your role and responsibilities.',
          },
          // ... add more experiences
        ],
        education: [
          {
            institution: 'Institution Name',
            degree: 'Degree Name',
            duration: 'Start Date - End Date',
            description: 'A brief description of your studies.',
          },
          // ... add more education entries
        ],
        // ... add more sections as needed
      },
    },

    app1996: {
      profilePicture: '/pat_96.jpg',
      content: {
        name: 'Your Name', 
        title: 'Your Title',
        about: 'A brief description about yourself.',
        experience: [
          {
            company: 'Testing Company',
            position: 'Director, Cool',
            duration: 'Jan 1, 2020 - October 30, 2022',
            description: 'A brief description of your role and responsibilities.',
          },
          // ... add more experiences
        ],
        education: [
          {
            institution: 'Institution Name',
            degree: 'Degree Name',
            duration: 'Start Date - End Date',
            description: 'A brief description of your studies.',
          },
          // ... add more education entries
        ],
        // ... add more sections as needed
      },
    },   
    
    app1986: {
      profilePicture: '/baby_pat.jpeg',
      content: {
        name: 'Patrick Priestley',
        title: 'Baby',
        about: 'A brief description about yourself.',
        experience: [
          {
            company: "The Priestley's",
            position: 'Baby',
            duration: 'Feb 5, 1986 - May 31, 1992',
            description: 'A brief description of your role and responsibilities.',
          },
          // ... add more experiences
        ],
        education: [
          {
            institution: 'Institution Name',
            degree: 'Degree Name',
            duration: 'Start Date - End Date',
            description: 'A brief description of your studies.',
          },
          // ... add more education entries
        ],
        // ... add more sections as needed
      },
    },
};

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('appPresent');
    const content = contentData[theme] || contentData.present; // Add fallback to 'present'
  
    return (
      <ThemeContext.Provider value={{ theme, setTheme, content }}>
        {children}
      </ThemeContext.Provider>
    );
  };
  
  export default ThemeContext;
