import React, { useContext, useEffect } from 'react';
import ThemeContext from './ThemeContext';
import './styles/app1986.css';
import './styles/app1996.css';
import './styles/app2006.css';
import './styles/app2016.css';
import './styles/appPresent.css';
import './styles/responsive.css';


// Replace these with the URLs to your actual social media profiles
const LINKEDIN_URL = 'https://www.linkedin.com/in/wppriestley/';

function App() {
  const { theme, setTheme, content } = useContext(ThemeContext);

  useEffect(() => {
    document.documentElement.classList = '';
    document.documentElement.classList.add(theme);
  }, [theme]);

  return (
    <div className="App">
      <div className="main-container">
        <header>
          <div className="header-container">
          <div className="style-switcher">
            <label htmlFor="style-selector"></label>
            <select id="style-selector" value={theme} onChange={(e) => setTheme(e.target.value)}>
              <option value="appPresent">2023</option>
              <option value="app2016">2016</option>
              <option value="app2006">2006</option>
              <option value="app1996">1996</option>
              <option value="app1986">1986</option>
            </select>
          </div>
            <div className="header-image-optional">
              <img src="/MIKEY.gif" alt="Mikey" />
              <img src="/patrick-fire.gif" alt="Patrick's Website" />
              <img src="/GOK.gif" alt="Goku" />
            </div>
          </div>
        </header>
        <main>
          <div className="content-container">
            <div className="profile-container">
              <div className='profile-picture-container'>
                <img src={content.profilePicture} alt="Profile" className="profile-picture" />
                <div className="social-links-profile">
                  <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/linkedin.png" alt="LinkedIn" />
                  </a>
                  <a href="https://github.com/PatrickPriestley" target="_blank" rel="noopener noreferrer">
                    <img src="/github-mark.png" alt="GitHub" />
                  </a>
                  <a href="/PatrickPriestley.pdf" download="PatrickPriestley.pdf.pdf">
                    <img src="/resume_icon.png" alt="Resume" />
                  </a>
                </div>
                <div className="animated-image"></div>
              </div>
              <div className="resume-info">
                <h1>{content.content.name}</h1>
                <h2>{content.content.title}</h2>
                <p>{content.content.about}</p>

                <h3>Experience</h3>
                {content.content.experience.map((exp, index) => (
                  <div key={index}>
                    <h4>{exp.company}</h4>
                    <h5>{exp.position}</h5>
                    <p>{exp.duration}</p>
                    <p>{exp.description}</p>
                  </div>
                ))}

                <h3>Education</h3>
                {content.content.education.map((edu, index) => (
                  <div key={index}>
                    <h4>{edu.institution}</h4>
                    <h5>{edu.degree}</h5>
                    <p>{edu.duration}</p>
                    <p>{edu.description}</p>
                  </div>
                ))}
                {/* Add more content sections as needed */}
              </div>
            </div>
          </div>
        </main>
      </div>
      <footer>
        <div className="hit-counter">Hits: 792839</div>
        <p>© Patrick Priestley - All rights reserved</p>
      </footer>
    </div>
  );
}

export default App;
